import React, { useState, useContext, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Link,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import ProgressLoader from '../../Progress';
import { ConfigContext } from 'src/contexts';
import CustomPasswordField from 'src/components/CustomFormFields/CustomPasswordField';

import useBreakpoints from 'src/hooks/useBreakpoints';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { useMergedClasses } from 'tss-react';
import CustomTextField from '../../CustomFormFields/CustomTextField';
import { useProvideUser } from 'src/hooks/useUsers';
import useSnackbar from 'src/hooks/useSnackbar';
import ROUTES from '../../../constants/routes';
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';

const useStyles = makeStyles()((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  radioWrapper: {
    borderColor: theme.palette.grey['400'],
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
    color: theme.palette.grey['600'],
  },
  selectedRadioWrapper: {
    backgroundColor: 'rgba(255, 228, 142, 0.19)',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
  },
}));

const fieldName_existing_password = 'existing_password';
const fieldName_new_password = 'new_password';
const fieldName_confirm_password = 'confirm_password';

function FirstTimeChangePasswordForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  let { classes } = useStyles();
  classes = useMergedClasses(classes, props.classes);
  const theme = useTheme();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { smBelow } = useBreakpoints();
  const [email, setEmail] = useState('');
  const { FirstTimeChangePassword, Login, userError } = useProvideUser();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const [cookie, setCookie] = useCookies(['name']);
  const username = new Cookies().get('username');
  const companyID = new Cookies().get('company_id');
  const roleID = new Cookies().get('role_id');
  const refID = new Cookies().get('ref_id');

  const validationSchema = Yup.object({
    [fieldName_existing_password]: Yup.string().required(
      'Existing password is required',
    ),
    [fieldName_new_password]: Yup.string().required('New password is required'),
    [fieldName_confirm_password]: Yup.string()
      .required('Confirm password is required')
      .oneOf(
        [Yup.ref(fieldName_new_password), null],
        'Confirm Password must match with New Password',
      ),
  });

  useEffect(() => {
    if (userError) {
      setLoading(false);
      showErrorSnackbar(`Failed to login. ${userError}`);
    }
  }, [userError]);

  const formikOnSubmit = async (values, actions) => {
    const res = await FirstTimeChangePassword(location.state.user_id, {
      curr_password: values.existing_password,
      new_password: values.new_password,
    });
    if (res.status === 'success' && res.body.is_success === true) {
      showSuccessSnackbar(`Password updated.`);
      setLoading(true);

      const loginRes = await Login(location.state.email, values.new_password);
      console.log('@@@ loginRes', loginRes);
      if (loginRes.status === 'success') {
        values.user_id = loginRes.body.users.user_id;
        values.email = location.state.email;
        values.password = values.new_password;
        redirectToDashboard(values);
      }
      setLoading(false);
    } else {
      showErrorSnackbar(`Opps, something went wrong, please try again later.`);
    }
  };

  const redirectToDashboard = async (values) => {
    if (username && refID) {
      try {
        await axios.put(
          `${process.env.REACT_APP_USERCONTACT_URL}/sysinfouserroles/accept/username/${username}/company/${companyID}/role/${roleID}`,
          {
            ref_id: refID,
            sysinfo_user_id: values.user_id,
          },
        );
        await new Cookies().remove('username');
        await new Cookies().remove('company_id');
        await new Cookies().remove('role_id');
        await new Cookies().remove('ref_id');
      } catch (e) {}
    }
    const redirectUri = searchParams.get('redirect_uri');
    if (redirectUri) {
      const clientID = searchParams.get('client_id');
      const qp_nonce = searchParams.get('nonce');
      const state = searchParams.get('state');

      const form = document.createElement('form');
      form.method = 'post';
      form.action = `${process.env.REACT_APP_AUTH_URL}/interaction/${state}/login`;

      const login = document.createElement('input');
      login.type = 'hidden';
      login.name = 'login';
      login.value = values.email;
      form.appendChild(login);

      const client_id = document.createElement('input');
      client_id.type = 'hidden';
      client_id.name = 'client_id';
      client_id.value = clientID;
      form.appendChild(client_id);

      const nonce = document.createElement('input');
      nonce.type = 'hidden';
      nonce.name = 'nonce';
      nonce.value = qp_nonce;
      form.appendChild(nonce);

      const password = document.createElement('input');
      password.type = 'hidden';
      password.name = 'password';
      password.value = values.password;
      form.appendChild(password);

      document.body.appendChild(form);

      form.submit();
      return;
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Card
        variant="outlined"
        style={{
          borderRadius: 16,
          border: smBelow ? 'none' : '',
          width: smBelow ? '100%' : '400px',
          maxWidth: '100%',
        }}
      >
        <CardContent>
          <Box py={2} fontWeight="700" fontSize="30px" textAlign="center">
            Reset password
          </Box>
          <Formik
            initialValues={{
              [fieldName_existing_password]: '',
              [fieldName_new_password]: '',
              [fieldName_confirm_password]: '',
            }}
            onSubmit={formikOnSubmit}
            validationSchema={validationSchema}
          >
            {(props) => {
              return (
                <Form style={{ width: '100%' }}>
                  <Box mb={2}>
                    <CustomPasswordField
                      label="Existing password"
                      name={fieldName_existing_password}
                      showStrength={true}
                    />
                  </Box>
                  <Box mb={2}>
                    <CustomPasswordField
                      label="New password"
                      name={fieldName_new_password}
                      showStrength={true}
                    />
                  </Box>
                  <Box mb={2}>
                    <CustomPasswordField
                      label="Confirm password"
                      name={fieldName_confirm_password}
                      showStrength={true}
                    />
                  </Box>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      color: '#FFFFFF',
                      textTransform: 'initial',
                      fontWeight: 700,
                    }}
                    size="large"
                    fullWidth={true}
                    disableElevation
                  >
                    Reset password
                  </Button>

                  <Box p={2}>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      align="left"
                    >
                      <Link
                        to="#"
                        onClick={(e) => {
                          window.location = 'mailto:' + email;
                          e.preventDefault();
                        }}
                        color="primary"
                        sx={{ cursor: 'pointer' }}
                      >
                        Contact us
                      </Link>{' '}
                      if resetting your password does not work.
                    </Typography>
                  </Box>
                  {msg && <p>{msg}</p>}
                  <Box
                    display="flex"
                    justifyContent="center"
                    mt={loading ? 2 : 0}
                  >
                    <ProgressLoader loading={loading} />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>

      <Box p={1}>
        <Typography variant="body2" align="center">
          <Link
            to="#"
            onClick={() => {
              redirectToDashboard(location.state);
            }}
            sx={{
              cursor: 'pointer',
              color: 'black',
              textDecorationColor: 'black',
            }}
          >
            I don&apos;t want to change password
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#fff',
      paddingRight: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}));

FirstTimeChangePasswordForm.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default FirstTimeChangePasswordForm;
